import React from 'react'
import { images } from 'config/images'

export const MobAppDevSerProps = {
  ppcbannerProps: {
    heading: (
      <>
        Innovative app experiences using our{' '}
        <span className="highlight-head">Mobile App Development Services </span>
      </>
    ),
    para: (
      <>
        In a mobile-first world, customers and employees want more ways to{' '}
        <strong>engage with your brand.</strong> Build mobile apps to meet their
        demands faster
      </>
    ),
    formCTA: 'Get project plan and consultation (FREE)',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Leading companies <span className="highlight-head">trust us</span> to
        deliver innovative mobile apps
      </>
    ),
    buildAppPara: (
      <>
        Partner with the company who shares your passion for building{' '}
        <span className="color-through">emotionally engaging apps.</span> From
        concept to the app store, watch your ideas become a beautiful reality.
        We create digital strategy, innovative design, and scalable
        architecture.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Our capabilities are{' '}
        <span className="highlight-head">result-oriented</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'iOS App Developmen',
        serviceParagraph: (
          <>
            From API integration to ARKit solutions — our expertise in
            Objective-C and Swift will help you develop the{' '}
            <strong>best iOS apps</strong> to meet the high demands of Apple’s
            fans.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'UX',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Android App Development',
        serviceParagraph: (
          <>
            <strong>Google-certified Android experts</strong> who have helped
            build successful Android mobile apps. Our developers are well versed
            with the updated Android platform, Java, Kotlin, C++ and Android
            studio.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Web',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Cross-Platform Mobile App Development',
        serviceParagraph: (
          <>
            Reduce the launch time and development costs by using{' '}
            <strong>cross-platform technologies</strong> without sacrificing
            performance. Whether it is Flutter, Xamarin, React Native and
            PhoneGap we can do it.{' '}
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'API',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'IoT App Development',
        serviceParagraph: (
          <>
            Develop <strong>high functioning apps</strong> with hardware
            interactions through Wi-Fi, Bluetooth, Near Field Communication
            (NFC), and more.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Re-engineering',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'VR and AR Apps Development',
        serviceParagraph: (
          <>
            Leverage the power of extended realities to deliver{' '}
            <strong>innovative applications</strong> and experiences to your
            users that were simply not possible before.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'Re-engineering',
        serviceClass: 'ice-blue',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Strategy and consulting to set the right goals',
        workingBenefitsContent: (
          <>
            We collaborate with you to understand your business needs, define
            the user experience, build a <strong>tech delivery roadmap</strong>{' '}
            and align the processes with your goals.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Product development approach to mobile apps',
        workingBenefitsContent: (
          <>
            Our creative technologists turn your ideas into apps with{' '}
            <strong>high ROI and retention rates</strong>. We use design
            thinking, user research, and usability testing throughout to ensure
            the mobile app appeals to your audience.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Incorporate Security Best Practices',
        workingBenefitsContent: (
          <>
            Mobile app <strong>testing best practice</strong> reduces the
            security risks, tests potential vulnerabilities and incorporates
            data encryption. This ensures that an application, APIs and its data
            is secure from potential attacks.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        <span className="highlight-head">Mobile apps</span> portfolio
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink: 'https://www.simform.com/mobile-app-performance/',
        featurecardTitle: 'How to Improve your Mobile App’s Performance?',
      },
      {
        blogLink: 'https://www.simform.com/mvc-mvp-mvvm-ios-app-development/',
        featurecardTitle:
          'Ending the debate: MVC vs MVP vs MVVM for iOS application development',
      },
      {
        blogLink:
          'https://www.simform.com/mobile-app-developers-database-selection/',
        featurecardTitle:
          'How to Choose the Right Mobile App Database for Your Application',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactCruben,
    personImg2x: images.contactCruben2x,
    personName: 'Ruben Ramirez',
    personDesignation:
      "Simplifying organization's digital transformation by building interactive software products.",
    personEmail: 'ruben@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
